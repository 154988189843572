// components/ghana/ReferralContext.js
import { createContext, useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const DEFAULT_REFERRAL_CODE = "2000"; // Same default as in GhPageHub

const ReferralContext = createContext();

export function ReferralProvider({ children }) {
  const [referralCode, setReferralCode] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  // On initial load or when URL changes
  useEffect(() => {
    // First check for referral in URL
    const urlParams = new URLSearchParams(window.location.search);
    let ref = urlParams.get('referral'); // Match your existing parameter name

    if (ref) {
      // Decode the referral code if it's URL-encoded
      try {
        ref = decodeURIComponent(ref);
      } catch (error) {
        ref = DEFAULT_REFERRAL_CODE;
      }

      // If referral starts with '*', ensure it ends with '#'
      if (ref.startsWith('*') && !ref.endsWith('#')) {
        ref += '#';
      }
      
      // Save to state
      setReferralCode(ref);
      
      // Save to localStorage for persistence
      localStorage.setItem('referralCode', ref);
      //console.log("Saved referral code to localStorage:", ref);
    } else {
      // If no ref in URL, try to retrieve from localStorage
      const storedRef = localStorage.getItem('referralCode');
      
      if (storedRef && storedRef !== referralCode) {
       // console.log("Retrieved referral from localStorage:", storedRef);
        setReferralCode(storedRef);
      }
    }
  }, [location.pathname]);

  // Function to apply referral to game
  const applyReferralToGame = () => {
    const codeToApply = referralCode || localStorage.getItem('referralCode') || DEFAULT_REFERRAL_CODE;
    //console.log(`Applying referral code: ${codeToApply}`);
    
    // You could add additional tracking or analytics here
    
    return codeToApply;
  };

  // Function to get current referral code from anywhere in the app
  const getCurrentReferral = () => {
    return referralCode || localStorage.getItem('referralCode') || DEFAULT_REFERRAL_CODE;
  };

  return (
    <ReferralContext.Provider value={{ 
      referralCode: referralCode || localStorage.getItem('referralCode') || DEFAULT_REFERRAL_CODE, 
      applyReferralToGame,
      getCurrentReferral
    }}>
      {children}
    </ReferralContext.Provider>
  );
}

export function useReferral() {
  return useContext(ReferralContext);
}