// App.js
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Nav from './components/ghana/Nav';
import Footer from './components/ghana/footer';
import { ReferralProvider } from './components/ghana/ReferralContext';
import AgeVerification from './components/AgeVerification';

// Lazy load all route components for code splitting
const GhPageHub = lazy(() => import('./pages/GhPageHub'));
const Howtoplay = lazy(() => import('./pages/Howtoplay'));
const Prizes = lazy(() => import('./pages/prizes'));
const Winners = lazy(() => import('./pages/winner'));
const FAQSection = lazy(() => import('./pages/faq'));
const TermsAndConditions = lazy(() => import('./pages/TermsAndConditions'));
const PrivacyPolicy = lazy(() => import('./pages/privacypolicy'));

// Loading fallback component
const LoadingFallback = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
    <div>Loading...</div>
  </div>
);

const defaultIpInfo = {
  ip: '0.0.0.0',
  city: 'Unknown',
  region: 'Unknown',
  country: 'Unknown',
  loc: '0,0',
  org: 'Unknown',
  postal: 'Unknown',
  timezone: 'UTC'
};

const ONE_DAY = 24 * 60 * 60 * 1000;
const IP_CACHE_KEY = 'ipInfoCache';
const IP_TIMESTAMP_KEY = 'ipInfoTimestamp';

function App() {
  // Memoize initial state to avoid unnecessary re-renders
  const [ipInfo, setIpInfo] = useState(() => {
    try {
      const cachedData = localStorage.getItem(IP_CACHE_KEY);
      const cachedTimestamp = localStorage.getItem(IP_TIMESTAMP_KEY);
      
      if (cachedData && cachedTimestamp && (Date.now() - parseInt(cachedTimestamp) < ONE_DAY)) {
        return JSON.parse(cachedData);
      }
    } catch (err) {
      //console.error('Error reading from localStorage:', err);
    }
    return defaultIpInfo;
  });
  
  const [ipError, setIpError] = useState(null);
  const [ipFetched, setIpFetched] = useState(false);

  // Fetch IP info only once on mount
  useEffect(() => {
    let isMounted = true;
    let controller = new AbortController();

    const fetchIpInfo = async () => {
      // Skip fetch if we already have valid data from initial state
      if (ipInfo.ip !== defaultIpInfo.ip && !ipFetched) {
        //console.log('Using initial cached IP info:', ipInfo);
        setIpFetched(true);
        return;
      }
      
      try {
        //console.log('Fetching IP info from GeoJS...');
        const response = await fetch('https://get.geojs.io/v1/ip/geo.json', {
          signal: controller.signal,
          cache: 'no-cache' // Ensure we get fresh data
        });
        
        if (!response.ok) {
          throw new Error(`Network response error: ${response.status}`);
        }
        
        const data = await response.json();
        
        if (!isMounted) return;
        
        const transformedData = {
          ip: data.ip || defaultIpInfo.ip,
          city: data.city || defaultIpInfo.city,
          region: data.region || defaultIpInfo.region,
          country: data.country || defaultIpInfo.country,
          loc: (data.latitude && data.longitude)
            ? `${data.latitude},${data.longitude}`
            : defaultIpInfo.loc,
          org: data.organization || defaultIpInfo.org,
          postal: data.postal || defaultIpInfo.postal,
          timezone: data.timezone || defaultIpInfo.timezone,
        };

        try {
          localStorage.setItem(IP_CACHE_KEY, JSON.stringify(transformedData));
          localStorage.setItem(IP_TIMESTAMP_KEY, Date.now().toString());
        } catch (storageErr) {
          //console.warn('Failed to cache IP info:', storageErr);
        }

        if (isMounted) {
          setIpInfo(transformedData);
          setIpError(null);
          setIpFetched(true);
        }
      } catch (error) {
        if (error.name === 'AbortError') {
          //console.log('IP info fetch aborted');
          return;
        }
        
        //console.error('Error fetching IP info:', error);
        if (isMounted) {
          setIpError(error.message);
          setIpFetched(true);
        }
      }
    };

    // Use requestIdleCallback for non-critical tasks when browser is idle
    if (window.requestIdleCallback) {
      window.requestIdleCallback(() => fetchIpInfo());
    } else {
      // Fallback for browsers that don't support requestIdleCallback
      setTimeout(fetchIpInfo, 100);
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [ipInfo.ip, ipFetched]);

  // Always define hooks, but conditionally log based on environment
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      console.log('Current IP info state:', ipInfo);
      if (ipError) {
        console.log('IP info error state:', ipError);
      }
    }
  }, [ipInfo, ipError]);

  return (
    <div>
      <ReferralProvider>
        <Nav />
        {ipError && process.env.NODE_ENV === 'development' && (
          <div
            style={{
              background: '#ffe6e6',
              color: '#cc0000',
              padding: '10px',
              textAlign: 'center',
            }}
          >
            IP location service error: {ipError}
          </div>
        )}
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            <Route
              path="/"
              element={
                <AgeVerification>
                  <GhPageHub ipInfo={ipInfo} />
                </AgeVerification>
              }
              index
            />
            <Route
              path="/gh"
              element={
                <AgeVerification>
                  <GhPageHub ipInfo={ipInfo} />
                </AgeVerification>
              }
            />
            <Route
              path="/howtoplay"
              element={
                <AgeVerification>
                  <Howtoplay />
                </AgeVerification>
              }
            />
            <Route
              path="/prizes"
              element={
                <AgeVerification>
                  <Prizes />
                </AgeVerification>
              }
            />
            <Route
              path="/winners"
              element={
                <AgeVerification>
                  <Winners />
                </AgeVerification>
              }
            />
            <Route
              path="/faq"
              element={
                <AgeVerification>
                  <FAQSection />
                </AgeVerification>
              }
            />
            <Route
              path="/termsConditions"
              element={
                <AgeVerification>
                  <TermsAndConditions />
                </AgeVerification>
              }
            />
            <Route
              path="/privacypolicy"
              element={
                <AgeVerification>
                  <PrivacyPolicy />
                </AgeVerification>
              }
            />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Suspense>
        <Footer />
      </ReferralProvider>
    </div>
  );
}

export default App;

// import { Routes, Route, Navigate } from 'react-router-dom';
// import GhPageHub from './pages/GhPageHub';
// import AgeVerification from './components/AgeVerification';
// import Howtoplay from './pages/Howtoplay';
// import Prizes from './pages/prizes';
// import Winners from './pages/winner';
// import FAQSection from './pages/faq';
// import TermsAndConditions from './pages/TermsAndConditions';
// import PrivacyPolicy from './pages/privacypolicy';
// import Nav from './components/ghana/Nav';
// import Footer from './components/ghana/footer';
// import { ReferralProvider } from './components/ghana/ReferralContext';

// function App() {
//   return (
//     <div>
//       <ReferralProvider>
//         {/* Nav component must be inside ReferralProvider to access the referral context */}
//         <Nav />
//         <Routes>
//           {/* AgeVerification will handle the logic for verifying age */}
//           <Route path="/" element={<AgeVerification><GhPageHub /></AgeVerification>} index />
//           <Route path="/gh" element={<AgeVerification><GhPageHub /></AgeVerification>} />
//           <Route path="/howtoplay" element={<AgeVerification><Howtoplay /></AgeVerification>} />
//           <Route path="/prizes" element={<AgeVerification><Prizes /></AgeVerification>} />
//           <Route path="/winners" element={<AgeVerification><Winners /></AgeVerification>} />
//           <Route path="/faq" element={<AgeVerification><FAQSection /></AgeVerification>} />
//           <Route path="/termsConditions" element={<AgeVerification><TermsAndConditions /></AgeVerification>} />
//           <Route path="/privacypolicy" element={<AgeVerification><PrivacyPolicy /></AgeVerification>} />
//           {/* Fallback to root */}
//           <Route path="*" element={<Navigate replace to="/" />} />
//         </Routes>
//         <Footer />
//       </ReferralProvider>
//     </div>
//   );
// }

// export default App;

// import { Routes, Route, Navigate } from 'react-router-dom';
// import GhPageHub from './pages/GhPageHub';
// import AgeVerification from './components/AgeVerification';
// import Howtoplay from './pages/Howtoplay';
// import Prizes from './pages/prizes';
// import Winners from './pages/winner';
// import FAQSection from './pages/faq';
// import TermsAndConditions from './pages/TermsAndConditions';
// import PrivacyPolicy from './pages/privacypolicy';
// import Nav from './components/ghana/Nav';
// import Footer from './components/ghana/footer';
// import ComingSoon from './pages/comingSoon';

// function App() {
//   return (
//     <div>
//         <Nav/>
//       <Routes>

//         {/* AgeVerification will handle the logic for verifying age */}
//         <Route path="/" element={<AgeVerification><GhPageHub /></AgeVerification>} index />
        

//        <Route path="/gh" element={<AgeVerification><GhPageHub /></AgeVerification>} />

//         {/* Add the How to Play route */}
//         <Route path="/howtoplay" element={<AgeVerification><Howtoplay /></AgeVerification>} />

//          {/* Add the How to Play route */}
//          <Route path="/prizes" element={<AgeVerification><Prizes /></AgeVerification>} />

//           {/* Add the How to Play route */}
//           <Route path="/winners" element={ <AgeVerification><Winners /></AgeVerification>} />

//             {/* Add the How to Play route */}
//             <Route path="/faq" element={<AgeVerification><FAQSection /></AgeVerification>} />

//             <Route path="/termsConditions" element={<AgeVerification><TermsAndConditions /></AgeVerification>} />

//             <Route path="/privacypolicy" element={<AgeVerification><PrivacyPolicy /></AgeVerification>} />

            


//         {/* Fallback to root */}
//         <Route path="*" element={<Navigate replace to="/" />} />
//       </Routes>
//     <Footer/>
//     </div>
//   );
// }

// export default App;
