// components/ghana/ReferralLink.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useReferral } from './ReferralContext';

const ReferralLink = ({ to, children, ...props }) => {
  const { getCurrentReferral } = useReferral();
  
  // Get current referral code
  const referralCode = getCurrentReferral();
  
  // Construct the URL with referral parameter
  let linkTo = to;
  
  // Only add referral if it's not the default code
  if (referralCode && referralCode !== "2000") {
    // Check if target already has query params
    const hasQueryParams = to.includes('?');
    const separator = hasQueryParams ? '&' : '?';
    
    linkTo = `${to}${separator}referral=${encodeURIComponent(referralCode)}`;
  }
  
  return (
    <Link to={linkTo} {...props}>
      {children}
    </Link>
  );
};

export default ReferralLink;