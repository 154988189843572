// components/ghana/ReferralNavLink.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useReferral } from './ReferralContext';

const ReferralNavLink = ({ to, children, ...props }) => {
  const { getCurrentReferral } = useReferral();
  
  // Get current referral code
  const referralCode = getCurrentReferral();
  
  // Construct the URL with referral parameter
  let linkTo = to;
  
  // Only add referral if it's not the default code
  if (referralCode && referralCode !== "2000") {
    // Check if target already has query params
    const hasQueryParams = to.includes('?');
    const separator = hasQueryParams ? '&' : '?';
    
    linkTo = `${to}${separator}referral=${encodeURIComponent(referralCode)}`;
  }
  
  return (
    <NavLink 
      to={linkTo} 
      className={({ isActive }) =>
        isActive
          ? "text-yellow-500 underline decoration-dashed"
          : "text-white hover:text-amber-700 duration-200"
      }
      {...props}
    >
      {children}
    </NavLink>
  );
};

export default ReferralNavLink;